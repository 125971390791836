import React from 'react';
import styled from 'styled-components';

const Leaf = ({ fill, top, left, rotation }) => {
  return (
    <Wrapper fill={fill} top={top} left={left} rotation={rotation}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="55"
        height="55"
        version="1.1"
        viewBox="0 0 55 55"
        xmlSpace="preserve"
      >
        <g transform="matrix(1.33333 0 0 -1.33333 -6.922 788.503)">
          <path
            className="leaf"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            strokeWidth="0.1"
            d="M18.507 588.546C8.435 563.842 29.81 552.904 29.81 552.904l-2.544 15.413 4.642-14.326c10.732 25.264-13.401 34.555-13.401 34.555"
          ></path>
        </g>
      </svg>
    </Wrapper>
  );
} 

export default Leaf;

const Wrapper = styled.div`
  position: absolute;
  top: ${({top}) => top};
  left: ${({left}) => left};
  transform: rotate(${(props) => props.rotation}deg);
  .leaf {
    fill: ${({fill}) => fill};
  }
`;