import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import Page from "../components/Page";
import MaddieTree from "../icon-wrappers/MaddieTree2";
import Leaf from '../icon-wrappers/Leaf';
import Resume from '../../static/resume.pdf';

const RootIndex = ({
  data: { allContentfulBlogPost, allContentfulPerson },
}) => {
  const blogPosts = allContentfulBlogPost.edges.map((post) => post.node);
  const featuredPost = blogPosts[0];
  const maddie = allContentfulPerson.edges[0].node;
  return (
    <Page>
      <div style={{height: 30}}></div>
      <LandingZone>
        <MaddieTree />
        <div className="title-wrapper">
          <h1>
            SPILL <span>GREEN</span> TEA
          </h1>
        </div>
        <div className="rando-leaves">
          <Leaf fill="#468F34" top="100px" left="20vw" rotation={110} />
          <Leaf fill="#A1C91F" top="45vh" left="75vw" rotation={90} />
        </div>
      </LandingZone>

      <PageContent>
        <div className="featured-preview">
          <h2 style={{ fontWeight: 400 }}>{featuredPost.title}</h2>
          <h3 style={{ fontWeight: 300, fontSize: "1.1em" }} className="skinny">
            {featuredPost.publishDate}
          </h3>
          <ReactMarkdown>{featuredPost.body.internal.content}</ReactMarkdown>
        </div>
        <div className="sidebar">
          <div className="profile">
            <div className="centerer">
              <p>Welcome, I'm {maddie.name}!</p>
            </div>
            <div className="centerer">
              <img src={maddie.image.file.url} alt="Author's face" />
            </div>
            <p>
              As a student of philosophy and computer science, I have a lot of
              essays and projects. I made Spill Green Tea to be a central
              repository for essays, projects, and thoughts that I think are
              share-worthy.
            </p>
            <p>
              If you'd like to collaborate on something (or just talk
              Aristotle), hit me up on
              <a href="https://www.linkedin.com/in/madeleine-beller-1a22531b6/">
                {" "}
                LinkedIn
              </a>.
            </p>
            <p onClick={(e) => {
              e.preventDefault();
              window.open(Resume, '_blank');
            }}>
              If you'd like to work together, check out my <a href={Resume}>resume</a>.
            </p>
          </div>
          <div className="recent-posts">
            <h3>Recent Posts</h3>
            <ul>
              {blogPosts.map((post) => {
                return (
                  <li key={post.title}>
                    <Link to={`/blog/${post.slug}`}>{post.title}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </PageContent>
    </Page>
  );
};

export default RootIndex;

const PageContent = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 350px;
  grid-column-gap: 80px;
  img {
    max-height: 550px;
  }
  .sidebar {
    .profile {
      img {
        height: 240px;
        clip-path: circle(50% at 50% 50%);
      }
    }
    .recent-posts {
      margin-top: 130px;
      a {
        color: ${({ theme }) => theme.pastel};
        :hover {
          color: black;
        }
      }
    }
  }
`;

const LandingZone = styled.div`
  height: 300px;
  margin-bottom: 145px;
  .title-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    h1 {
      position: relative;
      z-index: 1;
      font-size: 4.2em;
      font-weight: 200;
      span {
        color: ${({ theme }) => theme.grass};
      }
    }
  }
  .maddie-tree {
    position: absolute;
    z-index: 0;
    left: -450px;
    top: 10px;
    transform: rotate(70deg);
  }
`;


export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          subtitle
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            file {
              url
            }
          }
          widePicture
          body {
            internal {
              content
            }
          }
          author {
            shortBio {
              internal {
                content
              }
            }
            image {
              file {
                url
              }
            }
            name
          }
        }
      }
    }
    allContentfulPerson(filter: { name: { eq: "Maddie Beller" } }) {
      edges {
        node {
          id
          name
          image {
            file {
              url
            }
          }
          shortBio {
            internal {
              content
            }
          }
        }
      }
    }
  }
`;
